Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "reservations2";
exports.labelBodyText = "reservations2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.getBedsAPI = "bed_types";
exports.getInsurancesAPI = "insurances";
exports.getHospitalInfoAPI = 'hospital_bookings/hospital_info';
exports.getHospital= "hospital_bookings/available_hospitals";
exports.makePaymentAPI = "ccavenue/initiate_payment";
exports.bookHospitalAPI = "hospital_bookings";
exports.patchAPiMethod = "PATCH";
exports.postApiMethod = "POST";
exports.doctorApiMethod = "medic_reservations/available_medics";
exports.medicReservation = "medic_reservations/medic_availabilities";
exports.createMedicReservation = "medic_reservations"
exports.regex = "/\s+-\s+/g";
// Customizable Area End