import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  classes?: any;
  getHpList?: any;
  closePopup?: any;
  selectedFilters?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  hpFilterType: string;
  selectedIndex: number | number[];
  hpRole: string;
  languagesData: any;
  selectedFilters: any;
  selectedExpFilter: any;
  departmentsData:any;
  selectedPriceFilter:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FilterController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getLanguagesApiCallId: string = "";
  getDepartmentsApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      hpFilterType: "0",
      selectedIndex: 0,
      hpRole: "",
      languagesData: [],
      selectedFilters: this.props.selectedFilters,
      selectedExpFilter: [],
      departmentsData:[],
      selectedPriceFilter:[],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleLangugesResponse(responseJson, apiRequestCallId);
      this.handleDepartmentsResponse(responseJson, apiRequestCallId);
    }
  
  }

  handleLangugesResponse = (responseJson: any, apiRequestCallId: any) => {
    if(responseJson && apiRequestCallId === this.getLanguagesApiCallId){
      this.setState({
       languagesData: responseJson?.data?.attributes?.languages || []
      })
    }
  }

  handleDepartmentsResponse = (responseJson: any, apiRequestCallId: any) => {
    if(responseJson && apiRequestCallId === this.getDepartmentsApiCallId){
      this.setState({
       departmentsData: responseJson?.data
      })
    }
  }

  hpFilterType = (type: string) => {
    this.setState({
      hpFilterType: type,
      selectedIndex: parseInt(type) > 4 ? [] : 0
    });
  };

  hpFilterClick = (typeIndex: string, type: string, value: object | string = []) => {
    this.setState(prevState => ({
      hpFilterType: typeIndex,
    }));
  }

  handleCheckboxChange = (index: number) => {
    if (parseInt(this.state.hpFilterType) > 4) {
      const selectedIndexes = this.state.selectedIndex as number[];
      const newSelectedIndexes = selectedIndexes.includes(index)
        ? selectedIndexes.filter(i => i !== index)
        : [...selectedIndexes, index];
      this.setState({ selectedIndex: newSelectedIndexes });
    } else {
      this.setState({ selectedIndex: index });
    }
  };

    clearFilter(key: string) {
      const tempData = this.state.selectedFilters;
      const data = tempData.filter((filter: any) => {
        const filterKey = Object.keys(filter)[0];
        return filterKey !== key;
      });
      this.setState({
        selectedFilters: data
      })

      this.props.getHpList(data);
    }

    handleClearAll = async () => {

        if (this.state.hpFilterType === "0") {
          this.clearFilter("schedule_type");
        } else if (this.state.hpFilterType === "1") {
          this.clearFilter("shift");
        } else if (this.state.hpFilterType === "2") {
          this.setState({ selectedExpFilter: [] });
          this.clearFilter("experience");
        } else if (this.state.hpFilterType === "3") {
          this.clearFilter("language");
        } else if (this.state.hpFilterType === "5") {
          this.clearFilter("departments");
        }
        else if (this.state.hpFilterType === "6") {
          this.clearFilter("price");
        }
        else if (this.state.hpFilterType === "7") {
          this.clearFilter("slot");
        }
    }


handleFilterCheckboxChange = async (index: number, value: string | any, filterType: string, name: string) => {
  this.setState(prevState => {
      let updatedFilters = prevState.selectedFilters.length > 0 ? [...prevState.selectedFilters] : [];
      if (filterType === "experience") {
          return this.updateExperienceFilter(prevState, updatedFilters, name);
      } else if (filterType === "price"){
        return this.updatePriceFilter(prevState, updatedFilters, name);
      }
        else {
          return this.updateOtherFilters(prevState, updatedFilters, filterType, name, value);
      }
  });
};

updateExperienceFilter = (prevState: any, updatedFilters: any[], name: string) => {
  const isSelected = prevState.selectedExpFilter?.includes(name);
  const selectedExpFilter = isSelected
      ? prevState.selectedExpFilter?.filter((filter: any) => filter !== name)
      : [name];
  updatedFilters = updatedFilters.filter(filter => !filter.experience);
  updatedFilters.push({ experience: selectedExpFilter });

  return {
      selectedExpFilter: selectedExpFilter,
      selectedFilters: updatedFilters
  };
};

updatePriceFilter = (prevState: any, updatedFilters: any[], name: string) => {
  const isSelected = prevState.selectedPriceFilter?.includes(name);
  const selectedPriceFilter = isSelected
      ? prevState.selectedPriceFilter?.filter((filter: any) => filter !== name)
      : [name];
  updatedFilters = updatedFilters.filter(filter => !filter.price);
  updatedFilters.push({ price: selectedPriceFilter });

  return {
      selectedPriceFilter: selectedPriceFilter,
      selectedFilters: updatedFilters
  };
};


updateOtherFilters = (prevState: any, updatedFilters: any[], filterType: string, name: string, value: any) => {
  const existingFilter = updatedFilters.find(filter => filter[filterType]);
  const filterValues = existingFilter ? existingFilter[filterType] : [];
  const isCurrentlySelected = filterValues.some((filter: any) => filter.name === name);

  const updatedFilterValues = isCurrentlySelected
      ? filterValues.filter((filter: any) => filter.name !== name)
      : [...filterValues, { name: name, value: value }];

  if (existingFilter) {
      updatedFilters = updatedFilters.map(filter =>
          filter[filterType] !== undefined
              ? { [filterType]: updatedFilterValues }
              : filter
      );
  } else {
      updatedFilters.push({ [filterType]: updatedFilterValues });
  }

  return {
      selectedFilters: updatedFilters
  };
};

getRowSlotStyles = (isSelected: boolean) => {
  return {
    padding: "12px 0px",
    borderBottom: "1px solid #ddd",
    fontSize: "14px",
    display:"flex",
    gap:"10px",
    fontWeight: isSelected ? 600 : 500,
    width: "100%",
  };
};

getSelectedSlotField = (isSelected: boolean) => {
  return {
    fontSize: "18px",
    fontFamily: isSelected ? "SF Pro Text Medium" : "SF Pro Text Regular",
    padding: "2px 0px",
    color: "#292929",
    caretColor: "transparent"
  };
};

  getSelectedField = (isSelected: boolean) => {
    return {
      borderBottom: "1px solid #ddd",
      fontSize: "16px",
      fontFamily: isSelected ? "SF Pro Text Medium" : "SF Pro Text Regular",
      padding: "15px 0px",
      color: "#292929",
      caretColor: "transparent"
    };
  };

  getRowStyles = (isSelected: boolean) => {
    return {
      padding: "5px 0px",
      borderBottom: "1px solid #ddd",
      fontSize: "14px",
      fontWeight: isSelected ? 600 : 500,
      width: "14px"
    };
  };

  userRole = async () => {
    const hpRole = (await getStorageData("serviceType") || await getStorageData("hpUserRole")).toLowerCase();
    const filterType =  hpRole === "doctors" ? "5" : hpRole === "dietician" || hpRole === "physiotherapist" ? "6" : this.state.hpFilterType
    this.setState({
      hpRole,
      hpFilterType: filterType,
      selectedIndex: hpRole === "doctors" ? [] : this.state.selectedIndex
    });
  };

  getPopoverClass = () => {
    const { hpFilterType } = this.state;
    if (["4", "5", "6"].includes(hpFilterType)) {
      return {
        background: "white",
        position: "absolute" as "absolute",
        top: "calc(100% + 52px)",
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
        padding: "20px",
        maxWidth: "530px",
        minWidth: "530px",
        transform: "translateX(-105%)"
      };
    } else {
      return {
        background: "white",
        position: "absolute" as "absolute",
        top: "calc(100% + 52px)",
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
        padding: "20px",
        maxWidth: "430px",
        minWidth: "400px",
        transform: "translateX(-105%)"
      };
    }
  };

  checkSelectedField = (selectedId: string) => {
    if (this.state.hpFilterType === selectedId) {
      return {
        cursor: "pointer",
        fontSize: "18px",
        fontFamily: "SF Pro Text Semibold",
        color: "rgb(55, 128, 224)",
        background: "#C3D9F6",
        marginRight: "20px",
        padding: "16px 25px",
        borderRadius: "8px",
        marginBottom: "20px",
        caretColor: "transparent",
        width: "93px",
        display: "flex"
      }
    } else{
        return {
          cursor: "pointer",
          fontSize: "18px",
          fontFamily: "SF Pro Text Medium",
          color: "#808080",
          background: "transparent",
          marginRight: "20px",
          padding: "16px 25px",
          borderRadius: "8px",
          marginBottom: "20px",
          caretColor: "transparent",
          width: "93px",
          display: "flex"
        }
    }
  };

  getLanguages = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.getLanguagesApiCallId = requestMessage.messageId;
    let url = `/patient_profile_details`;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      const authToken = await getStorageData("authToken");
      const header = {
        token: authToken,
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  
  };

  handleApply = async () => {
      await this.props.getHpList(this.state.selectedFilters);
      this.props.closePopup();
  }


  getDepartmentDetails = async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.getDepartmentsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.departmentsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    const authToken = await getStorageData("authToken");
    const header = {
      token: authToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  // Customizable Area End
}
